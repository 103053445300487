/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from 'vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import Vuex from 'vuex'
import App from './App.vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'
import router from './router'
const axios = require('axios').default
const instance = axios.create({
  baseURL:'https://console.maximini.com/'
})
import './scss/app.scss';
const connect = require('./config_developpemenrt')

Vue.use(Antd);
Vue.use(Vuex);
Vue.config.productionTip = false

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);
const store = new Vuex.Store({
  state: {
    granted: false,
    dataAnnu:[],
    renderComponent: true,
  },
  mutations: {
    granted(state) {
      state.granted = !state.granted
    },
    reverseRenderComponent(state) {
      state.renderComponent = !state.renderComponent
    },
    pushDataAnnu(state, chunk){
  state.dataAnnu.push(chunk)
    },
    updateElementDataAnnu(state,chunk){
      state.dataAnnu[chunk.ind]= chunk.chunkItem
    },
    reverseDataAnnu(state){
      state.dataAnnu = state.dataAnnu.reverse()
    }
  },
  actions: {
    storeDataAnnu(context,chunk){
      context.commit('pushDataAnnu',chunk)

    },
    renderComponent(context){
      context.commit('reverseRenderComponent')
    },
    dataActualisation(context){
      axios.get(`${connect.baseUri}/annuaire-info`).then((response) => {
        let annuInfoAxios= response.data.user
        for(let i=0;i<annuInfoAxios.length;i++){
          context.commit('updateElementDataAnnu',
        {
          ind:i,
          chunkItem:{
            id: annuInfoAxios[i].id,
            name: annuInfoAxios[i].title,
            url: annuInfoAxios[i].url,
            annu_rubrique: annuInfoAxios[i].annu_rubrique,
            annu_infoData: annuInfoAxios[i]
          }
        })
        }
        //context.commit('reverseDataAnnu')
      })
    }
  },
  getters: {
    getAnnuData: state =>{

      return  state.dataAnnu.reverse()
    },
    getRenderComponent: state =>{
      return state.renderComponent
    }
  }
})
new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')